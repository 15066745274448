<template>
  <div id="App">
    <van-steps :active="2">
      <van-step>选择日期</van-step>
      <van-step>选择场地时间</van-step>
      <van-step>预约完成</van-step>
    </van-steps>
    <van-divider>预约信息</van-divider>
    <van-row type="flex">
      <van-col span="4"></van-col>
      <van-col span="18">
        <van-field
          :label="'会员卡号：' + hykh"
          label-width="100%"
          label-align="left"
        />
        <van-field
          :label="'预约日期：' + yyrq"
          label-width="100%"
          label-align="left"
        />
        <van-field
          :label="'预约时间：' + yysj"
          label-width="100%"
          label-align="left"
        />
        <van-field
          :label="'场地名称：' + cdmc"
          label-width="100%"
          label-align="left"
        />
        <van-field
          :label="'预约费用：￥' + fyhj"
          label-width="100%"
          label-align="left"
        />
        <van-field
          :label="'卡上余额：￥' + ksye"
          label-width="100%"
          label-align="left"
        />
      </van-col>
      <van-col span="2"></van-col>
    </van-row>
    <van-divider style="color:red;font-size:x-large">预约成功</van-divider>
    <Tabbar></Tabbar>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Tabbar from "../components/Tabbar.vue";
import { Dialog } from "vant";
export default {
  data() {
    return {
      hykh: "",
      yyrq: "",
      yysj: "",
      cdmc: "",
      fyhj: "",
      ksye: ""
    };
  },
  computed: {
    ...mapState(["userInfo"])
  },
  components: {
    Tabbar
  },
  created: function() {
    var that = this;
    that
      .$axios({
        method: "post",
        url: "/api/Main",
        data: {
          Method: "API_getYYXX",
          Params: [that.$route.query.code]
        },
        headers: {
          Authorization: "Bearer " + that.userInfo.token
        }
      })
      .then(response => {
        if (response.data.status == "200") {
          that.hykh = response.data.Table[0].HYKH;
          that.yyrq = response.data.Table[0].YYRQ;
          that.yysj = response.data.Table[0].YYSJ;
          that.cdmc = response.data.Table[0].CDMC;
          that.fyhj = response.data.Table[0].FYHJ;
          that.ksye = response.data.Table[0].KSYE;
        } else {
          Dialog({ message: response.data.msg });
          that.finished = true;
        }
      })
      .catch(error => {
        //弹出失败提醒
        Dialog({ message: error.message });
        that.finished = true;
      });
  }
};
</script>